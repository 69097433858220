import { Injectable } from '@angular/core';
import { Observable, catchError, of, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { UserModel } from '../../models/user.model';
import { environment } from '../../../../../environments/environment';
import { AuthModel, AuthModelResponse } from '../../models/auth.model';

const API_USERS_URL = `${environment.apiUrl}/auth`;
const API_UAA = `${environment.apiUrl}/uaa`;

@Injectable({
  providedIn: 'root',
})
export class AuthHTTPService {
  constructor(private http: HttpClient) { }

  // public methods
  login(email: string, password: string): Observable<any> {
    const loginData = {
      username: email,
      password: password,
      applicationId: environment.applicationId
    };
    return this.http.post<AuthModelResponse>(`${API_UAA}/login`, loginData)
  }

  // CREATE =>  POST: add a new user to the server

  // Your server should check email => If email exists send link to the user and return true | If email doesn't exist return false
  forgotPassword(email: string): Observable<boolean> {
    return this.http.post<boolean>(`${API_USERS_URL}/forgot-password`, {
      email,
    });
  }

  getUserByToken(token: string): Observable<UserModel> {
    const parts = token.split('.');

    // Decode the payload (middle part)
    const decodedPayload = JSON.parse(atob(parts[1]));
    console.log(decodedPayload)
    const exp = JSON.parse(atob(parts[1])).exp;
    // console.log(decodedPayload)
    const userData: UserModel = {
      email: decodedPayload.email,
      username: decodedPayload.preferred_username,
      userId:decodedPayload.sub,
      password: "string",
      fullname: "string",
      pic: "string",
      roles: decodedPayload.roles,
      occupation: "string",
      companyName: "string",
      phone: "string",
      id: decodedPayload.userId,
      firstname: '',
      lastname: '',
      website: '',
      language: '',
      timeZone: '',
      communication: {
        email: false,
        sms: false,
        phone: false
      },
      setUser: function (_user: unknown): void {
        throw new Error('Function not implemented.');
      },
      authToken: '',
      refreshToken: '',
      expiresIn: exp,
      setAuth: function (auth: AuthModel): void {
        throw new Error('Function not implemented.');
      }
    };
    if (Date.now() >= exp * 1000) {
      return of();
    } else {
      return of(userData);

    }
    // Return the user data as an observable
  }
  private handleError(error: HttpErrorResponse) {
    if (error.status === 400 && error.error && error.error.status === 'error' && error.error.data && error.error.data.error) {
      const fieldErrors = error.error.data.error.exception.fieldErrors;
      let errorMessage = '';
      for (const field in fieldErrors) {
        if (fieldErrors.hasOwnProperty(field)) {
          fieldErrors[field].forEach((error: any) => {
            errorMessage += `${error.message}\n`;
          });
        }
      }

      console.error('Bad Request:', errorMessage);
      return throwError(errorMessage);
    } else {
      return throwError('Something went wrong. Please try again later.');
    }
  }
  getMentorDetails(id: number) {
    const authLocalStorageToken = localStorage.getItem('authLocalStorageToken');
    let authToken: string | null = null;

    if (authLocalStorageToken) {
      authToken = JSON.parse(authLocalStorageToken).authToken;
    }
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `${authToken}`);
    return this.http.get(`${environment.apiUrl}/mentre/users/mentor/${id}`, { headers })
  }

  getMasterData() {
    const authLocalStorageToken = localStorage.getItem('authLocalStorageToken');
    let authToken: string | null = null;

    if (authLocalStorageToken) {
      authToken = JSON.parse(authLocalStorageToken).authToken;
    }
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `${authToken}`);
    return this.http.get(`${environment.apiUrl}/mentre/master/state`, { headers })
  }

  updatePassWord(payload:any): Observable<any> {
    return this.http.post<AuthModelResponse>(`${API_UAA}/user/changepassword`, payload)
  }
}
